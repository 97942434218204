<template>
  <div class="career">
    <section class="section">
      <MSectionHeader
        class="header"
        title="Alătură-te Echipei Noastre! Transformă-ți Visul în Succes!"
        description="La Biroul de consultanta Husanu, ne dedicăm nu doar inovării și excelenței în domeniul nostru, ci și creșterii fiecărui membru al echipei noastre. Creăm un mediu unde poți învăța, crește și prospera atât profesional, cât și personal. Explorează oportunitățile noastre diversificate și găsește drumul tău unic în cadrul companiei noastre dinamică."
      />
    </section>
    <section class="section section__center">
      <div class="team">
        <MMemberCard v-for="(member, i) in members" :key="i" :member="member" />
      </div>
    </section>
    <section class="section" ref="#how-it-works">
      <MSectionHeader
        class="header"
        title="Cum să Aplici"
        description="Dacă dorești să te alături echipei noastre, te invităm să urmezi pașii de mai jos pentru a aplica. Procesul nostru este structurat pentru a ne asigura că găsim cei mai potriviți candidați pentru pozițiile disponibile."
      />
    </section>
    <section class="section">
      <MSteps :steps="steps" class="steps" />
    </section>
    <section class="section">
      <OServices
        :services="services"
        title="Întrebări Frecvente"
        :link="false"
      />
    </section>
  </div>
</template>
<script>
import MSectionHeader from "@/components/molecules/m-section-header";
import MMemberCard from "@/components/molecules/m-member-card";
import MSteps from "@/components/molecules/m-steps";
import OServices from "@/components/organisms/o-services";

export default {
  components: {
    MSectionHeader,
    MMemberCard,
    MSteps,
    OServices,
  },
  data() {
    return {
      carouselSettings: {
        animationDuration: 400,
        rewind: true,
        slidePerPage: false,
        perView: 4,
        peek: {
          before: 0,
          after: 40,
        },
        breakpoints: {
          768: {
            perView: 1,
            peek: {
              before: 30,
              after: 30,
            },
          },
        },
      },
      services: [
        {
          name: "Ce tip de training oferiți angajaților noi?",
          content: {
            title: "Ce tip de training oferiți angajaților noi?",
            list: [
              "Oferim o gamă variată de training-uri pentru angajații noi, incluzând:",
              "- Sesiuni interne: Training-uri specifice proceselor și procedurilor noastre, susținute de echipa internă.",
              "- Workshop-uri externe: Ateliere conduse de experți din diverse domenii, care acoperă subiecte variate, precum dezvoltarea abilităților tehnice și managementul proiectelor.",
              "- Program de mentorat: Angajații noi sunt ghidați de colegi cu experiență pentru suport continuu și integrare rapidă.",
              "- E-learning: Acces la cursuri online și resurse digitale pentru învățare flexibilă.",
              "- Feedback și evaluări: Evaluări periodice și feedback continuu pentru a ajusta și îmbunătăți programele de training.",
              "Aceasta abordare cuprinzătoare asigură integrarea și dezvoltarea profesională eficientă a noilor angajați.",
            ],
          },
        },
        {
          name: "Cum sunt evaluate performanțele?",
          content: {
            title: "Cum sunt evaluate performanțele?",
            list: [
              "Evaluările performanțelor se bazează pe mai mulți factori:",
              "- Obiective specifice rolului: Performanța este măsurată în funcție de realizarea obiectivelor stabilite pentru fiecare rol în parte.",
              "- Feedbackul colegilor: Opiniile și observațiile colegilor contribuie la o evaluare mai completă și obiectivă.",
              "- Rezultatele directe ale muncii: Analizăm rezultatele și impactul muncii fiecărui angajat pentru a evalua eficiența și calitatea contribuției lor.",
              "Această abordare holistică ne permite să avem o imagine clară și echitabilă a performanței fiecărui angajat.",
            ],
          },
        },
      ],
      steps: [
        {
          title: "Completează Formularul Online",
          list: [
            "Aplică prin completarea formularului nostru online. Accesează link-ul de mai jos pentru a începe aplicația:",
            "[Completează formularul online]",
          ],
          image: require("@/assets/icons/consultation.png"),
        },
        {
          title: "Trimite CV-ul",
          list: [
            "Alternativ, poți trimite CV-ul și o scrisoare de intenție la adresa noastră de email:",
            "[adresa de email]",
          ],
          image: require("@/assets/icons/task.png"),
        },
        {
          title: "Vino la interviu",
          description:
            "Procesul nostru de selecție include interviuri inițiale, teste de competență și o sesiune de interviuri finale.",
          image: require("@/assets/icons/deal.png"),
        },
      ],
      quote: {
        title: "Use the average cost effect:",
        description:
          "If an investment account's savings rate remains constant over the long term, depending on the fund's price development, fewer shares will be purchased at higher prices and more shares at lower prices. In this way, investors can take advantage of price fluctuations to achieve a cheaper average starting price over the long term than if they regularly buy a fixed number of shares over the same period. This method is particularly recommended for equity fund investments.",
      },
      members: [
        {
          name: "Oportunități de Școlarizare",
          role: "Educație pentru Excelență",
          description:
            "Investim în dezvoltarea continuă a partenerilor noștri prin programe intensive de școlarizare. Colaborăm cu universități de top și instituții de formare profesională pentru a-ți oferi cursuri avansate în [domenii specifice, ex. tehnologie, management, etc.]. Aceste programe sunt concepute pentru a-ți asigura cunoștințele necesare pentru a excela în cariera ta.",
          image: require("@/assets/images/career/scholarship_sm.jpg"),
        },
        {
          name: "Programe de Practică",
          role: "Practică pentru Succes",
          description:
            "Oferim programe de practică structurate pentru a te pregăti pentru provocările reale ale industriei. Practicanții noștri beneficiază de mentorat individual și șansa de a lucra la proiecte care impactează direct succesul partenerilor . Multe dintre talentele noastre actuale și-au început cariera printr-un astfel de program de practică.",
          image: require("@/assets/images/career/study_sm.jpg"),
        },
        {
          name: "Oportunități de Câștig",
          role: "Bonusuri pentru Performanță",
          description:
            "Compensăm generos eforturile și talentul, cu pachete competitive de  bonusuri bazate pe performanță. Suntem dedicați să oferim bonusuri care nu doar că recunosc munca ta valoroasă, dar și te stimulează să te auto-depășești continuu.",
          image: require("@/assets/images/career/money_sm.jpg"),
        },
        {
          name: "Oportunități de Avansare",
          role: "Carieră Bazată pe Merit",
          description:
            "Avansarea în cadrul Companiei este bazată pe merit și performanță. Fiecare partener are un plan de carieră personalizat, discutat periodic cu supervizorii, pentru a asigura că știi întotdeauna ce pași să urmezi pentru a ajunge acolo unde îți dorești.",
          image: require("@/assets/images/career/study2_sm.jpg"),
        },
      ],
      characteristics: [
        {
          title: "Evaluarea Financiară",
          subtitle: "Înțelegerea situației tale financiare.",
          list: [
            "Analiză detaliată a veniturilor și cheltuielilor.",
            "Evaluare a activelor și datoriilor.",
            "Ghidare în procesul de evaluare completă.",
          ],
        },
        {
          title: "Stabilirea Obiectivelor",
          subtitle: "Setarea obiectivelor financiare.",
          list: [
            "Definirea obiectivelor realiste și personalizate.",
            "Economisire pentru achiziții majore sau pensionare.",
            "Planificare pentru investiții pe termen lung.",
          ],
        },
        {
          title: "Planificarea Investițiilor",
          subtitle: "Selectarea investițiilor potrivite.",
          list: [
            "Prezentarea diferitelor opțiuni de investiții.",
            "Alegerea investițiilor în funcție de profilul de risc.",
            "Alignarea investițiilor cu obiectivele financiare.",
          ],
        },
        {
          title: "Gestionarea Riscurilor",
          subtitle: "Minimizarea riscurilor financiare.",
          list: [
            "Importanța asigurărilor și protecției financiare.",
            "Strategii pentru reducerea riscurilor financiare.",
            "Implementarea unor metode de protecție financiară.",
          ],
        },
        {
          title: "Optimizarea Fiscală",
          subtitle: "Maximizarea veniturilor și minimizarea taxelor.",
          list: [
            "Identificarea oportunităților fiscale.",
            "Ghidare în aplicarea unor strategii fiscale eficiente.",
            "Minimizarea impactului fiscal asupra veniturilor.",
          ],
        },
        {
          title: "Educație Financiară",
          subtitle: "Îmbunătățirea cunoștințelor financiare.",
          list: [
            "Acces la resurse și sfaturi pentru alegeri informate.",
            "Creșterea nivelului de educație financiară.",
            "Dezvoltarea abilităților financiare pentru gestionarea eficientă a banilor.",
          ],
        },
      ],
    };
  },
  mounted() {
    window.scrollTo({ top: 0 });
  },
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.career {
  padding: 96px var(--spacer-xl);
  margin: 0 auto;
  max-width: 1272px;
  @include for-mobile {
    padding: 84px var(--spacer-sm);
  }
  .header {
    text-align: center;
  }
}
.section {
  max-width: 1272px;
  margin: 0 auto;
  margin: var(--spacer-2xl) 0;
  @include for-mobile {
    margin: 0;
  }
  &__center {
    text-align: center;
  }
}
.steps {
  &::v-deep .perk__content {
    max-width: 14rem;
  }
}
.quote {
  display: block;
  margin: var(--spacer-xl) 0 0;
  padding: 0.75rem var(--spacer-sm);
  border-left: 4px solid var(--c-primary);
  font-family: var(--font-family-secondary);
  font-size: 0.85rem;
  &__title {
    font-weight: var(--font-bold);
    margin: 0 0 var(--spacer-xs);
  }
  &__description {
    font-weight: var(--font-normal);
    margin: 0;
  }
}
.team {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: var(--spacer-2xl);
}
.apply-section {
  @include for-mobile {
    margin-top: var(--spacer-2xl);
  }
}
</style>
